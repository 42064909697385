// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_title__LiXxn {
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
}

.styles_subTitle__5NP-p {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

.styles_label__mnrjT {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

.styles_body__jK1M1 {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
}

a {
  color: inherit;
  text-underline-offset: 4px;
  opacity: 0.7;
  transition: all 0.3s ease-in-out;
}
a:hover {
  opacity: 1;
}

p {
  margin: 0;
}

.styles_publication__iSIGx {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}
.styles_publication__iSIGx .styles_titleWithLink__cpynf {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
}
.styles_publication__iSIGx .styles_popup__35x8Q {
  fill: var(--text-color);
  width: 10px;
  opacity: 0.7;
  transition: all 0.3s ease-in-out;
}
.styles_publication__iSIGx .styles_popup__35x8Q:hover {
  opacity: 1;
}`, "",{"version":3,"sources":["webpack://./src/styles/typography.scss","webpack://./src/page/publications/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;ACCF;;ADEA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;ACCF;;ADEA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;ACCF;;ADEA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;ACCF;;ADEA;EACE,cAAA;EAEA,0BAAA;EACA,YAAA;EACA,gCAAA;ACAF;ADEE;EACE,UAAA;ACAJ;;ADIA;EACE,SAAA;ACDF;;AAlCA;EACE,aAAA;EACA,sBAAA;EACA,WAAA;AAqCF;AAnCI;EACE,aAAA;EACA,mBAAA;EACA,WAAA;EACA,mBAAA;AAqCN;AAlCI;EACE,uBAAA;EACA,WAAA;EACA,YAAA;EACA,gCAAA;AAoCN;AAlCM;EACE,UAAA;AAoCR","sourcesContent":[".title {\n  font-size: 32px;\n  font-weight: 700;\n  line-height: 40px;\n}\n\n.subTitle {\n  font-size: 24px;\n  font-weight: 700;\n  line-height: 32px;\n}\n\n.label {\n  font-size: 18px;\n  font-weight: 700;\n  line-height: 24px;\n}\n\n.body {\n  font-size: 16px;\n  font-weight: 400;\n  line-height: 28px;\n}\n\na {\n  color: inherit;\n  // text-decoration: none;\n  text-underline-offset: 4px;\n  opacity: 0.7;\n  transition: all 0.3s ease-in-out;\n\n  &:hover {\n    opacity: 1;\n  }\n}\n\np {\n  margin: 0;\n}","@import \"../../styles/typography.scss\";\n\n.publication {\n  display: flex;\n  flex-direction: column;\n  gap: 0.8rem;\n\n    .titleWithLink {\n      display: flex;\n      flex-direction: row;\n      gap: 0.5rem;\n      align-items: center;\n    }\n\n    .popup {\n      fill: var(--text-color);\n      width: 10px;\n      opacity: 0.7;\n      transition: all 0.3s ease-in-out;\n\n      &:hover {\n        opacity: 1;\n      }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `styles_title__LiXxn`,
	"subTitle": `styles_subTitle__5NP-p`,
	"label": `styles_label__mnrjT`,
	"body": `styles_body__jK1M1`,
	"publication": `styles_publication__iSIGx`,
	"titleWithLink": `styles_titleWithLink__cpynf`,
	"popup": `styles_popup__35x8Q`
};
export default ___CSS_LOADER_EXPORT___;
