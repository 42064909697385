// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Raleway);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --background-color: #f0f0f0;
  --card-color: #2a2b2e;
  --dropdown-color: #c5c8ce;
  --text-color: #202124;
  --border-color: rgba(255,255,255,0.3);
}

html {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Raleway";
  overflow-x: hidden;
  color: var(--text-color);
  background-color: var(--background-color);
}

* {
  box-sizing: border-box;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  font-family: "Raleway";
}`, "",{"version":3,"sources":["webpack://./src/styles/variables.scss","webpack://./src/styles/index.scss"],"names":[],"mappings":"AAAA;EACE,2BAAA;EACA,qBAAA;EACA,yBAAA;EACA,qBAAA;EACA,qCAAA;ACEF;;AAJA;EACI,SAAA;EACA,UAAA;EACA,YAAA;EACA,aAAA;AAOJ;;AAJA;EACI,SAAA;EACA,UAAA;EACA,sBAAA;EACA,kBAAA;EACA,wBAAA;EACA,yCAAA;AAOJ;;AAFA;EAEI,sBAAA;EACA,kCAAA;EACA,mCAAA;EACA,wCAAA;EACA,2BAAA;EACA,sBAAA;AAKJ","sourcesContent":[":root {\n  --background-color: #f0f0f0;\n  --card-color: #2a2b2e;\n  --dropdown-color: #c5c8ce;\n  --text-color: #202124;\n  --border-color: rgba(255,255,255,0.3);\n}\n\n$hover-duration: 0.3s;","@import './variables.scss';\n@import url('https://fonts.googleapis.com/css2?family=Raleway');\n\nhtml {\n    margin: 0;\n    padding: 0;\n    width: 100vw;\n    height: 100vh;\n}\n\nbody {\n    margin: 0;\n    padding: 0;\n    font-family: 'Raleway';\n    overflow-x: hidden;\n    color: var(--text-color);\n    background-color: var(--background-color);\n    // transition: background-color #{$theme-duration} ease-out,\n    //     color #{$theme-duration} ease-out;\n}\n\n* {\n    -webkit-box-sizing: border-box;\n    box-sizing: border-box;\n    -moz-osx-font-smoothing: grayscale;\n    -webkit-font-smoothing: antialiased;\n    -webkit-tap-highlight-color: transparent;\n    -webkit-touch-callout: none;\n    font-family: 'Raleway';\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
