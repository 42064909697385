import { Page } from "../../component/page"
import { TitleWithLine } from "../../component/title-with-line"
import data from "../../data/home.json"
import styles from "./styles.module.scss"
import { ReactComponent as PopUpIcon } from "./popup-icon.svg"

export const Home = ({}) => {
  return (
    <Page>
      <TitleWithLine content={data.introduction.title} />
      <div className={styles.body}>
        { data.introduction.body }
      </div>

      <TitleWithLine content={data.interests.title} />
      <div className={styles.body}>
        { data.interests.body.map((interest, i) => (
          <div>
            { interest }
          </div>
        )) }
      </div>

      <TitleWithLine content={data.faculty.title} />
      <div className={styles.faculty}>
        { data.faculty.body.map((member, i) => (
          <div className={`${styles.body} ${styles.member}`}>
            <img src={member.img} />
            <div className={styles.name}>
              { member.name }
              <a href={member.web}>
                <PopUpIcon />
              </a>
            </div>
            <a href={`mailto:${member.email}`}>{ member.email }</a>
            <ul>
              { member.field.map((interest, i) => (
                <li>
                  { interest }
                </li>
              ))}
            </ul>
          </div>
        )) }
      </div>
    </Page>
  )
}

export default Home