// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_title__LYLvi {
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
}

.styles_subTitle__5YDVr {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

.styles_label__hqtur {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

.styles_body__vis3r {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
}

a {
  color: inherit;
  text-underline-offset: 4px;
  opacity: 0.7;
  transition: all 0.3s ease-in-out;
}
a:hover {
  opacity: 1;
}

p {
  margin: 0;
}

.styles_body__vis3r {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.styles_body__vis3r p {
  white-space: pre;
}`, "",{"version":3,"sources":["webpack://./src/styles/typography.scss","webpack://./src/page/contact/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;ACCF;;ADEA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;ACCF;;ADEA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;ACCF;;ADEA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;ACCF;;ADEA;EACE,cAAA;EAEA,0BAAA;EACA,YAAA;EACA,gCAAA;ACAF;ADEE;EACE,UAAA;ACAJ;;ADIA;EACE,SAAA;ACDF;;AAjCA;EACE,aAAA;EACA,sBAAA;EACA,WAAA;AAoCF;AAlCE;EACE,gBAAA;AAoCJ","sourcesContent":[".title {\n  font-size: 32px;\n  font-weight: 700;\n  line-height: 40px;\n}\n\n.subTitle {\n  font-size: 24px;\n  font-weight: 700;\n  line-height: 32px;\n}\n\n.label {\n  font-size: 18px;\n  font-weight: 700;\n  line-height: 24px;\n}\n\n.body {\n  font-size: 16px;\n  font-weight: 400;\n  line-height: 28px;\n}\n\na {\n  color: inherit;\n  // text-decoration: none;\n  text-underline-offset: 4px;\n  opacity: 0.7;\n  transition: all 0.3s ease-in-out;\n\n  &:hover {\n    opacity: 1;\n  }\n}\n\np {\n  margin: 0;\n}","@import \"../../styles/typography.scss\";\n@import \"../../styles/layout.scss\";\n\n.body {\n  display: flex;\n  flex-direction: column;\n  gap: 0.5rem;\n\n  p {\n    white-space: pre;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `styles_title__LYLvi`,
	"subTitle": `styles_subTitle__5YDVr`,
	"label": `styles_label__hqtur`,
	"body": `styles_body__vis3r`
};
export default ___CSS_LOADER_EXPORT___;
