// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_page__y4qn7 {
  width: 90%;
  margin: 0 auto;
  padding-top: 128px;
  padding-bottom: 48px;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  overflow: hidden;
}
@media (min-width: 1024px) {
  .styles_page__y4qn7 {
    width: 70%;
  }
}`, "",{"version":3,"sources":["webpack://./src/component/page/styles.module.scss","webpack://./src/styles/layout.scss"],"names":[],"mappings":"AAEA;EACE,UAAA;EACA,cAAA;EACA,kBAAA;EACA,oBAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;EACA,gBAAA;AADF;ACQI;EDfJ;IAWI,UAAA;EAAF;AACF","sourcesContent":["@import \"../../styles/layout.scss\";\n\n.page {\n  width: 90%;\n  margin: 0 auto;\n  padding-top: 128px;\n  padding-bottom: 48px;\n  display: flex;\n  flex-direction: column;\n  gap: 2rem;\n  overflow: hidden;\n\n  @include respond-to(\"desktop\") {\n    width: 70%;\n  }\n}","// breakpoints\n$TABLET: 600px;\n$DESKTOP: 1024px;\n\n$breakpoints: (\n  'desktop': $DESKTOP,\n  'tablet': $TABLET,\n  'phone': 0px,\n) !default;\n\n$breakpointsfrom: (\n  'tablet': 1023px,\n  'phone': $TABLET,\n) !default;\n\n@mixin respond-to($breakpoint) {\n  @if map-has-key($breakpoints, $breakpoint) {\n    @media (min-width: map-get($breakpoints, $breakpoint)) {\n      @content;\n    }\n  }\n}\n@mixin respond-from($breakpoint) {\n  @if map-has-key($breakpointsfrom, $breakpoint) {\n    @media (max-width: map-get($breakpointsfrom, $breakpoint)) {\n      @content;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page": `styles_page__y4qn7`
};
export default ___CSS_LOADER_EXPORT___;
