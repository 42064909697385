import React from "react"
import { Page } from "../../component/page"
import { TitleWithLine } from "../../component/title-with-line"
import data from "../../data/projects.json"
import styles from "./styles.module.scss"

export const Projects = ({}) => {
  return (
    <Page>
      { data.map((project, i) => (
        <React.Fragment>
          <TitleWithLine content={project.name } />
          <div className={styles.project}>
            { project.img &&
              <img src={project.img} />
            }
            
            { project.about.map((about, i) => (
              <div className={styles.body}>
                { about.title &&
                  <p className={styles.bold}>{ about.title }</p>
                }
                <p>{ about.body }</p>
              </div>
            ))}
          </div>
        </React.Fragment>
      ))}
    </Page>
  )
}

export default Projects