import { Page } from "../../component/page"
import styles from "./styles.module.scss"
import data from "../../data/publications.json"
import { TitleWithLine } from "../../component/title-with-line"
import { ReactComponent as PopUp } from "./popup-icon.svg"

export const Publications = ({}) => {
  return (
    <Page>
      { data.map((publication, i) => (
        <>
          <TitleWithLine content={publication.year} />
          { publication.list.map((item, i) => (
            <div className={styles.publication}>
              <div className={styles.titleWithLink}>
                <div className={styles.label}>
                  { item.title }
                </div>
                <a href={item.link}>
                  <PopUp className={styles.popup}/>
                </a>
              </div>
              <p>{ item.authors }</p>
            </div>
          ))}
        </>
      ))}
    </Page>
  )
}

export default Publications