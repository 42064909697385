import styles from "./styles.module.scss"

export const TitleWithLine = ({
  content
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.subTitle}>
        { content }
      </div>
      <div className={styles.line} />
    </div>
  )
}