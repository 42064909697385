// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_title__4ydZy {
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
}

.styles_subTitle__WbgdC {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

.styles_label__W3kyR {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

.styles_body__V6v5x {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
}

a {
  color: inherit;
  text-underline-offset: 4px;
  opacity: 0.7;
  transition: all 0.3s ease-in-out;
}
a:hover {
  opacity: 1;
}

p {
  margin: 0;
}

.styles_project__5d5sS {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.styles_project__5d5sS .styles_bold__64Tzs {
  font-weight: bold;
}
.styles_project__5d5sS img {
  max-width: 480px;
}`, "",{"version":3,"sources":["webpack://./src/styles/typography.scss","webpack://./src/page/projects/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;ACCF;;ADEA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;ACCF;;ADEA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;ACCF;;ADEA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;ACCF;;ADEA;EACE,cAAA;EAEA,0BAAA;EACA,YAAA;EACA,gCAAA;ACAF;ADEE;EACE,UAAA;ACAJ;;ADIA;EACE,SAAA;ACDF;;AAlCA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AAqCF;AAnCE;EACE,iBAAA;AAqCJ;AAlCE;EACE,gBAAA;AAoCJ","sourcesContent":[".title {\n  font-size: 32px;\n  font-weight: 700;\n  line-height: 40px;\n}\n\n.subTitle {\n  font-size: 24px;\n  font-weight: 700;\n  line-height: 32px;\n}\n\n.label {\n  font-size: 18px;\n  font-weight: 700;\n  line-height: 24px;\n}\n\n.body {\n  font-size: 16px;\n  font-weight: 400;\n  line-height: 28px;\n}\n\na {\n  color: inherit;\n  // text-decoration: none;\n  text-underline-offset: 4px;\n  opacity: 0.7;\n  transition: all 0.3s ease-in-out;\n\n  &:hover {\n    opacity: 1;\n  }\n}\n\np {\n  margin: 0;\n}","@import \"../../styles/typography.scss\";\n\n.project {\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n\n  .bold {\n    font-weight: bold;\n  }\n\n  img {\n    max-width: 480px;\n    // margin: 0 auto;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `styles_title__4ydZy`,
	"subTitle": `styles_subTitle__WbgdC`,
	"label": `styles_label__W3kyR`,
	"body": `styles_body__V6v5x`,
	"project": `styles_project__5d5sS`,
	"bold": `styles_bold__64Tzs`
};
export default ___CSS_LOADER_EXPORT___;
