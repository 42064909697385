import styles from "./styles.module.scss";
import { ReactComponent as PopUpIcon } from "./popup-icon.svg";

const Faculty = ({ faculty }) => {
  return (
    <div className={styles.faculty}>
      {faculty.body.map((member, i) => (
        <div className={`${styles.body} ${styles.member}`}>
          <img src={member.img} alt={member.name} />
          <div className={styles.info}>
            <div className={styles.name}>
              {member.name}
              <a href={member.web}>
                <PopUpIcon />
              </a>
            </div>
            <a className={styles.email} href={`mailto:${member.email}`}>{member.email}</a>
            <ul className={styles.field}>
              {member.field.map((interest, i) => (
                <li>{interest}</li>
              ))}
            </ul>


          </div>
        </div>
      ))}
    </div>
  );
};

export default Faculty;
