import Contact from "./page/contact";
import Home from "./page/home";
import Projects from "./page/projects";
import Publications from "./page/publications";

export const routes = [
  {
    path: "/",
    element: Home,
  },
  {
    path: "/home",
    element: Home,
  },
  {
    path: "/projects",
    element: Projects,
  },
  {
    path: "/publications",
    element: Publications,
  },
  {
    path: "/contact",
    element: Contact,
  },
]

export default routes;