import React, { useEffect, useState } from "react"
import styles from "./styles.module.scss"
import { useLocation, useNavigate } from "react-router-dom"

const TABS = [
  "home",
  "projects",
  "publications",
  "contact"
]

export const Header = ({}) => {
  const location = useLocation()
  const navigate = useNavigate()
  const tabs = TABS
  const [currTab, setCurrTab] =  useState(location.pathname?.split("/")[1])

  useEffect(() => {
    if (location.pathname?.split("/")[1])
      setCurrTab(location.pathname?.split("/")[1])
    else
      setCurrTab(TABS[0])
  }, [location])

  const maxWidth = 768
  const [width, setWidth] = useState(global.innerWidth)
  const [menuIsOpen, setMenuIsOpen] = useState(false)

  const showBurger = () => {
    return width < maxWidth
  }

  useEffect(() => {
    const onResize = () => {
      setWidth(global.innerWidth)
    }

    global.addEventListener("resize", onResize)

    return(() => {
      global.removeEventListener("resize", onResize)
    })
  }, [width])

  const handleBurgerOnClick = () => {
    setMenuIsOpen((prevState) => !prevState)
  }

  return (
    <div className={styles.header}>
      <div className={styles.logo}>
        <img src="image/imlab.png" />
      </div>
      { showBurger() ? 
        <svg className={styles.burger} onClick={handleBurgerOnClick}>
          <line x1="12%" y1="50%" x2="88%" y2="50%"
            className={`${styles.top} ${menuIsOpen ? styles.opened : ""}`} />
          <line x1="12%" y1="50%" x2="88%" y2="50%"
            className={`${styles.middle} ${menuIsOpen ? styles.opened : ""}`} />
          <line x1="12%" y1="50%" x2="88%" y2="50%"
            className={`${styles.bottom} ${menuIsOpen ? styles.opened : ""}`} />
        </svg>
        :
        <div className={styles.nav}>
          { tabs.map((tab, id) => (
            <div
              className={`${tab === currTab ? styles.selected : ""}`}
              key={id}
              onClick={() => navigate(tab)}
            >
              { tab }
            </div>
          ))}
        </div>
      }
      { showBurger() && menuIsOpen &&
        <div className={styles.menu}>
          { tabs.map((tab, id) => (
            <div
              className={`${tab === currTab ? styles.selected : ""}`}
              key={id}
              onClick={() => {
                setMenuIsOpen(false)
                navigate(tab)
              }}
            >
              { tab }
            </div>
          ))}
        </div>
      }
    </div>
  )
}

export default Header