import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from "./component/header"
import routes from "./routes";

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        { routes.map(({ path, element: Elem }) => (
            <Route path={path} key={path} element={<Elem />} />
          ))
        }
      </Routes>
    </Router>
  );
}

export default App;
