import Faculty from "../../component/faculty"
import { Page } from "../../component/page"
import { TitleWithLine } from "../../component/title-with-line"
import data from "../../data/home.json"
import styles from "./styles.module.scss"


export const Home = () => {
  return (
    <Page>
      <TitleWithLine content={data.introduction.title} />
      <div className={styles.body}>
        {data.introduction.body[0]}
        <a href={data.introduction.projects.link}>{data.introduction.projects.title}</a>
        {data.introduction.body[1]}
        <a href={data.introduction.email.link}>{data.introduction.email.title}</a>
      </div>

      <TitleWithLine content={data.interests.title} />
      <div className={styles.body}>
        {data.interests.body.map((interest, i) => (
          <div>
            {interest}
          </div>
        ))}
      </div>

      <TitleWithLine content={data.PI.title} />
      <Faculty faculty={data.PI} />
      <TitleWithLine content={data.CoPI.title} />
      <Faculty faculty={data.CoPI} />
    </Page>
  )
}

export default Home