// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_title__OMv2i {
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
}

.styles_subTitle__8gwV7 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

.styles_label__je43B {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

.styles_body__AJ3IH {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
}

a {
  color: inherit;
  text-underline-offset: 4px;
  opacity: 0.7;
  transition: all 0.3s ease-in-out;
}
a:hover {
  opacity: 1;
}

p {
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/styles/typography.scss","webpack://./src/page/home/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;ACCF;;ADEA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;ACCF;;ADEA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;ACCF;;ADEA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;ACCF;;ADEA;EACE,cAAA;EAEA,0BAAA;EACA,YAAA;EACA,gCAAA;ACAF;ADEE;EACE,UAAA;ACAJ;;ADIA;EACE,SAAA;ACDF","sourcesContent":[".title {\n  font-size: 32px;\n  font-weight: 700;\n  line-height: 40px;\n}\n\n.subTitle {\n  font-size: 24px;\n  font-weight: 700;\n  line-height: 32px;\n}\n\n.label {\n  font-size: 18px;\n  font-weight: 700;\n  line-height: 24px;\n}\n\n.body {\n  font-size: 16px;\n  font-weight: 400;\n  line-height: 28px;\n}\n\na {\n  color: inherit;\n  // text-decoration: none;\n  text-underline-offset: 4px;\n  opacity: 0.7;\n  transition: all 0.3s ease-in-out;\n\n  &:hover {\n    opacity: 1;\n  }\n}\n\np {\n  margin: 0;\n}",".title {\n  font-size: 32px;\n  font-weight: 700;\n  line-height: 40px;\n}\n\n.subTitle {\n  font-size: 24px;\n  font-weight: 700;\n  line-height: 32px;\n}\n\n.label {\n  font-size: 18px;\n  font-weight: 700;\n  line-height: 24px;\n}\n\n.body {\n  font-size: 16px;\n  font-weight: 400;\n  line-height: 28px;\n}\n\na {\n  color: inherit;\n  text-underline-offset: 4px;\n  opacity: 0.7;\n  transition: all 0.3s ease-in-out;\n}\na:hover {\n  opacity: 1;\n}\n\np {\n  margin: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `styles_title__OMv2i`,
	"subTitle": `styles_subTitle__8gwV7`,
	"label": `styles_label__je43B`,
	"body": `styles_body__AJ3IH`
};
export default ___CSS_LOADER_EXPORT___;
